<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-contract"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel">
          <b-col>
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <!-- Product -->
                  <b-row class="item-dados">
                    <b-col>
                      <h5 class="titulo-item">
                        {{ $t('seller.contracts.right_preview.text_1794') }}
                      </h5>

                      <b-col cols="12">
                        <div class="product-main">
                          <div class="img-product">
                            <img
                              :src="
                                contract.contract.live.product.images[0].path
                              "
                              v-if="
                                !loading &&
                                contract.contract.live.product &&
                                contract.contract.live.product.images.length
                              "
                            />
                          </div>
                          <div
                            class="name-product"
                            v-if="!loading && contract.contract.live.product"
                          >
                            <span
                              >{{
                                $t('seller.contracts.right_preview.text_1794')
                              }}: #{{ contract.contract.live.product.id }}</span
                            >
                            <p>{{ contract.contract.live.product.name }}</p>
                            <p class="hash" v-if="contract.contract.offer">
                              Código da Oferta:
                              {{ contract.contract.offer.hash }}
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <!-- Code -->
                    <b-col cols="6">
                      <p class="label-campo">
                        {{ $t('seller.contracts.right_preview.text_1789') }}
                      </p>
                      <p class="dados-campo">{{ contract.id }}</p>
                    </b-col>
                    <!-- Status -->
                    <b-col cols="6">
                      <p class="label-campo">
                        {{ $t('seller.contracts.right_preview.text_1790') }}
                      </p>
                      <p
                        class="dados-campo"
                        :class="getStatus(contract.status).class"
                      >
                        {{ getStatus(contract.status).text }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <!-- Beginning -->
                    <b-col cols="6">
                      <p class="label-campo">
                        {{ $t('seller.contracts.right_preview.text_1791') }}
                      </p>
                      <p class="dados-campo" v-if="contract.start_date">
                        {{ contract.start_date | date }}
                      </p>
                    </b-col>
                    <!-- Next Expiration -->
                    <b-col cols="6" v-if="contract.current_period_end">
                      <p class="label-campo">
                        {{ $t('seller.contracts.right_preview.text_1792') }}
                      </p>
                      <p class="dados-campo">
                        {{ contract.current_period_end | date }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <!-- Product Type -->
                    <b-col cols="6">
                      <p class="label-campo">
                        {{ $t('seller.contracts.right_preview.text_253') }}
                      </p>
                      <p class="dados-campo">
                        {{
                          getProductType(contract.contract.live.product.type)
                            .text
                        }}
                      </p>
                    </b-col>
                    <!-- Method -->
                    <b-col cols="6">
                      <p class="label-campo">
                        {{ $t('seller.contracts.right_preview.text_1793') }}
                      </p>
                      <p class="dados-campo">
                        {{
                          contract.history && contract.history.length
                            ? getMethod(contract.history[0].method)
                            : $t('seller.contracts.right_preview.text_1811')
                        }}
                      </p>
                    </b-col>
                  </b-row>

                  <template
                    v-if="
                      interAccess && contract.history && contract.history.length
                    "
                  >
                    <!-- Local Currency -->
                    <b-col
                      v-if="contract.history[0].local_currency != undefined"
                      cols="12"
                      class="mt-4"
                    >
                      <p class="label-campo mr-2">Moeda da compra</p>
                      <span>{{ contract.history[0].local_currency.name }}</span>
                    </b-col>
                    <!-- Base Currency -->
                    <b-col
                      v-if="contract.history[0].base_currency != undefined"
                      cols="12"
                      class="mt-4"
                    >
                      <p class="mr-2 label-campo">Valor recebido em</p>
                      <section class="d-flex align-items-center">
                        <div
                          class="chip"
                          :style="
                            setColors(
                              contract.history[0].base_currency.currency
                            )
                          "
                        >
                          {{
                            contract.history[0].base_currency
                              ? contract.history[0].base_currency
                                  .currency_symbol
                              : 'R$'
                          }}
                        </div>
                        <span>
                          {{ contract.history[0].base_currency.currency }}</span
                        >
                      </section>
                    </b-col>
                    <!-- Country -->
                    <b-col
                      v-if="contract.history[0].country != undefined"
                      cols="12"
                      class="mt-4"
                    >
                      <p class="mr-2 label-campo">Origem da compra</p>
                      <section class="d-flex align-items-center">
                        <img
                          class="mr-2"
                          style="height: 30px"
                          :src="
                            require(`@/assets/locales/flag-${contract.history[0].country.country_code}.svg`)
                          "
                        />
                        <span> {{ contract.history[0].country.name }}</span>
                      </section>
                    </b-col>
                  </template>
                </b-col>
              </b-row>
              <!-- Client -->
              <b-row class="item-dados">
                <b-col v-if="contract.client">
                  <h5 class="titulo-item">
                    {{ $t('seller.contracts.right_preview.text_1798') }}
                  </h5>

                  <p class="label-campo">
                    {{ $t('seller.contracts.right_preview.text_1796') }}
                  </p>
                  <p class="dados-campo mb-3">{{ contract.client.name }}</p>

                  <p class="label-campo" v-if="isSeller">
                    {{ $t('seller.contracts.right_preview.text_1797') }}
                  </p>
                  <p class="dados-campo mb-3" v-if="isSeller">
                    {{ contract.client.email }}
                  </p>

                  <p class="label-campo" v-if="isSeller">Telefone</p>
                  <p class="dados-campo mb-3" v-if="isSeller">
                    <span v-if="/\#/.test(contract.client.cellphone)">
                      {{ contract.client.cellphone }}
                    </span>

                    <a
                      v-else
                      :href="formatTel(contract.client.cellphone)"
                      target="_blank"
                      class="whats-cliente"
                      >{{ contract.client.cellphone }}
                      <font-awesome-icon :icon="['fab', 'whatsapp']"
                    /></a>
                  </p>

                  <p class="label-campo" v-if="isSeller">
                    {{
                      contract.client.document_type
                        ? contract.client.document_type
                        : 'CPF'
                    }}
                  </p>
                  <p class="dados-campo mb-3">{{ contract.client.document }}</p>aa
                </b-col>
              </b-row>
              <!-- Affiliate -->
              <b-row v-if="contract.affiliate" class="item-dados">
                <b-col>
                  <h5 class="titulo-item">
                    {{ $t('seller.contracts.right_preview.text_1795') }}
                  </h5>

                  <p class="label-campo">
                    {{ $t('seller.contracts.right_preview.text_1796') }}
                  </p>
                  <p class="dados-campo mb-3">{{ contract.affiliate.name }}</p>

                  <p class="label-campo">
                    {{ $t('seller.contracts.right_preview.text_1797') }}
                  </p>
                  <p class="dados-campo">{{ contract.affiliate.email }}</p>
                </b-col>
              </b-row>
              <!-- Transactions -->
              <b-row class="item-dados">
                <b-col>
                  <h5
                    v-b-toggle.collapse-2
                    class="titulo-item titulo-arrow"
                    :class="{ ativo: collapse }"
                    @click="collapse = !collapse"
                  >
                    {{ $t('seller.contracts.right_preview.text_1801') }}
                    <img src="@/assets/img/icons/arrow-fill.svg" alt="" />
                  </h5>

                  <b-collapse id="collapse-2" data-anima="top">
                    <b-row class="mt-3 mb-1">
                      <div class="label-campo"></div>
                      <b-col cols="3" class="titulo-collapse label-campo">
                        {{ $t('seller.contracts.right_preview.text_1802') }}
                      </b-col>

                      <b-col cols="3" class="titulo-collapse label-campo">
                        {{ $t('seller.contracts.right_preview.text_1803') }}
                      </b-col>

                      <b-col cols="3" class="titulo-collapse label-campo">
                        {{ $t('seller.contracts.right_preview.text_1804') }}
                      </b-col>

                      <b-col cols="3" class="titulo-collapse label-campo">
                        {{ $t('seller.contracts.right_preview.text_1805') }}
                      </b-col>
                    </b-row>

                    <!-- -- -->
                    <div v-if="contract.history && contract.history.length">
                      <div
                        v-for="item in contract.history"
                        :key="item.id"
                        class="history-item"
                        :class="getStatus_sales(item.status).class"
                        v-b-tooltip="{
                          title: getStatus_sales(item.status).text,
                          placement: 'right'
                        }"
                      >
                        <b-row>
                          <b-col
                            cols="2"
                            class="dados-campo titulo-collapse mt-1"
                          >
                            <span>{{ item.id }} </span></b-col
                          >

                          <b-col cols="3" class="dados-campo titulo-collapse">
                            <span> {{ item.created_at | date }}</span>
                          </b-col>

                          <b-col cols="4" class="dados-campo titulo-collapse">
                            <span v-if="item.paid_at">
                              {{ item.paid_at | date }}</span
                            >
                            <span v-else>-</span>
                          </b-col>

                          <b-col cols="3" class="dados-campo titulo-collapse">
                            <span v-if="item.balance">
                              {{
                                contract.history[0].base_currency
                                  ? contract.history[0].base_currency
                                      .currency_symbol
                                  : 'R$'
                              }}
                              {{ formatMoney(item.balance) }}
                            </span>
                            <a
                              class="openB"
                              :href="item.boleto_url"
                              target="_blank"
                              v-if="
                                item.method == 'BOLETO' &&
                                item.status != 'paid' &&
                                item.status != 'refunded'
                              "
                              >{{
                                $t('seller.contracts.right_preview.text_1806')
                              }}</a
                            >

                            <span
                              v-if="
                                !item.balance &&
                                item.method != 'BOLETO' &&
                                item.status != 'paid'
                              "
                            >
                              {{
                                contract.history[0].base_currency
                                  ? contract.history[0].base_currency
                                      .currency_symbol
                                  : 'R$'
                              }}
                              0,00
                            </span>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div v-else>
                      <p class="sem-historico">
                        {{ $t('seller.contracts.right_preview.text_247') }}
                      </p>
                    </div>
                  </b-collapse>
                </b-col>
              </b-row>

              <!-- Options -->
              <b-row class="item-dados">
                <b-col>
                  <h5 class="titulo-item">
                    {{ $t('seller.contracts.right_preview.text_1807') }}
                  </h5>
                  <div class="btn-flex">
                    <BaseConfirmDialog
                      class="d-inline-block"
                      @confirmed="cancelContract"
                      id="btn-cancela-contrato"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="accent_outline"
                          :outline="true"
                          :disabled="
                            contract.status == 'canceled' ||
                            contract.status == 'CANCELED' ||
                            contract.status == 'PROCESSING' ||
                            is_btn_disable
                          "
                          @click="openModal"
                        >
                          {{
                            contract.status == 'PROCESSING' ||
                            contract.status == 'processing'
                              ? $t('seller.contracts.right_preview.text_1808')
                              : $t('seller.contracts.right_preview.text_1809')
                          }}
                        </BaseButton>
                      </template>
                    </BaseConfirmDialog>

                    <b-tooltip
                      v-if="
                        contract.status == 'canceled' ||
                        contract.status == 'CANCELED'
                      "
                      target="btn-cancela-contrato"
                      :title="$t('seller.contracts.right_preview.text_1810')"
                    />

                    <Affiliation
                      v-if="
                        (contract.seller_id == userID &&
                          !contract.affiliate &&
                          contract.status == 'ACTIVE') ||
                        contract.status == 'paid'
                      "
                      :product_id="contract.contract.product_id"
                      from="contract"
                      @close="fetchContract"
                      class="d-inline-block"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="secondary"
                          :outline="true"
                          @click="openModal(contract_id)"
                          >{{
                            $t('seller.contracts.right_preview.text_248')
                          }}</BaseButton
                        >
                      </template>
                    </Affiliation>

                    <Subscription
                      v-if="
                        contract.seller_id == userID &&
                        contract.status == 'ACTIVE'
                      "
                      @close="fetchContract"
                      class="d-inline-block"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="info3"
                          @click="openModal(contract_id)"
                          >{{
                            $t('seller.contracts.right_preview.text_249')
                          }}</BaseButton
                        >
                      </template>
                    </Subscription>
                    <div v-if="isShowSubscriptionUpdateButton()">
                      <BaseButton variant="primary" @click="openSalesPage()">{{
                        $t('seller.contracts.right_preview.text_250')
                      }}</BaseButton>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>
<script>
import Affiliation from '@/components/shared/modals/Affiliation'
import { textMethod } from '@/helpers.js'
import { EventBus } from '@/main.js'
import Money from '@/mixins/money'
import ContractService from '@/services/resources/ContractService'
import moment from 'moment'
import Subscription from './modals/Subscription'

const service = ContractService.build()

export default {
  props: {
    updateQueryParams: {
      type: Function,
      default: () => {}
    }
  },
  name: 'DetalhesContrato',
  components: {
    Affiliation,
    Subscription
  },
  mixins: [Money],
  data() {
    return {
      loading: true,
      collapse: false,
      is_btn_disable: false,
      contract_id: -1,
      visible: false,
      contract: {
        contract: {
          live: {
            product: {
              name: '',
              description: '',
              images: [{ path: null }]
            }
          }
        },
        start_date: '',
        client: {
          name: null,
          email: null,
          cellphone: null
        },
        history: [
          {
            status: 'processing',
            updated_at: ''
          }
        ]
      },
      interAccess: false
    }
  },
  filters: {
    date(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
  },
  created() {
    let userInterIds = process.env.USER_INTER.substring(
      1,
      process.env.USER_INTER.length - 1
    ).split(',')
    this.interAccess = userInterIds.some(
      userId => parseInt(userId) === this.$store.getters.getProfile.id
    )
  },
  computed: {
    userID() {
      return this.$store.getters.getProfile.id
    },
    isSeller() {
      return this.$store.getters.isSeller
    },
    salesPage() {
      const id_produto = this.contract.contract.live.product.id
      let slug = `${id_produto}/offer/${this.contract.contract.offer.hash}?`

      // http://localhost:3000/assinatura/136/offer/uJbxb5?fn=Teste%20push%204&document=653.723.600-20&client_id=5972
      return (
        process.env.VUE_SALES_PAGE +
        '/assinatura/' +
        slug +
        'fn=' +
        encodeURI(this.contract.client.name) +
        '&document=' +
        encodeURI(this.contract.client.document) +
        '&client_id=' +
        this.contract.client_id
      )
    }
  },
  methods: {
    getMethod(method) {
      return textMethod(method)
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf('+55') > -1) {
          var tell = tel.replace(/[() -]/g, '')
          if (tell[0] === '+') {
            tell = tell.substring(1)
          }
          return `https://api.whatsapp.com/send?phone=${tell}`
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ''
          )}`
        }
      }
    },
    getProductType(type) {
      let r = {}
      if (type) {
        switch (type) {
          case 'TRANSACTION': {
            r = {
              text: this.$t('seller.contracts.right_preview.text_255'),
              class: 'icon-black'
            }
            break
          }
          case 'SUBSCRIPTION': {
            r = {
              text: this.$t('seller.contracts.right_preview.text_254'),
              class: 'icon-black'
            }
            break
          }
          default: {
            r = { text: type, class: 'icon-black' }
            break
          }
        }
        return r
      }
    },
    getStatus(status) {
      let r = {}
      if (status) {
        switch (status.toLowerCase()) {
          case 'trialing': {
            r = {
              text: this.$t('seller.contracts.text_2481'),
              class: 'icon-black'
            }
            break
          }
          case 'processing': {
            r = {
              text: this.$t('seller.contracts.text_2472'),
              class: 'icon-black'
            }
            break
          }
          case 'paid': {
            r = {
              text: this.$t('seller.contracts.text_2473'),
              class: 'icon-black'
            }
            break
          }
          case 'pending_payment': {
            r = {
              text: this.$t('seller.contracts.text_2474'),
              class: 'icon-black'
            }
            break
          }
          case 'unpaid': {
            r = {
              text: this.$t('seller.contracts.text_2457'),
              class: 'icon-red'
            }
            break
          }
          case 'canceled': {
            r = {
              text: this.$t('seller.contracts.text_2458'),
              class: 'icon-red'
            }
            break
          }
          case 'ended': {
            r = {
              text: this.$t('seller.contracts.text_2459'),
              class: 'icon-red'
            }
            break
          }

          default: {
            r = { text: status, class: 'icon-black' }
            break
          }
        }
        return r
      } else return { text: status, class: 'icon-black' }
    },
    getStatus_sales(status) {
      let r = {}
      if (status) {
        switch (status.toLowerCase()) {
          case 'processing': {
            r = {
              text: this.$t('seller.contracts.text_2472'),
              class: 'is-light-blue'
            }
            break
          }
          case 'authorized': {
            r = {
              text: this.$t('seller.contracts.text_2473'),
              class: 'is-light-blue'
            }
            break
          }
          case 'paid': {
            r = {
              text: this.$t('seller.contracts.text_2473'),
              class: 'is-green'
            }
            break
          }
          case 'refunded': {
            r = { text: this.$t('seller.contracts.text_2475'), class: 'is-red' }
            break
          }

          case 'waiting_payment': {
            r = {
              text: this.$t('seller.contracts.text_2476'),
              class: 'is-warning'
            }
            break
          }
          case 'refund_pending': {
            r = {
              text: this.$t('seller.contracts.text_2477'),
              class: 'is-warning'
            }
            break
          }
          case 'refused': {
            r = { text: this.$t('seller.contracts.text_2478'), class: 'is-red' }
            break
          }
          case 'chargedback': {
            r = {
              text: this.$t('seller.contracts.text_2479'),
              class: 'is-warning'
            }
            break
          }
          case 'delayed': {
            r = {
              text: this.$t('seller.contracts.text_2480'),
              class: 'is-green'
            }
            break
          }
          default: {
            r = { text: status, class: 'is-black' }
            break
          }
        }
        return r
      } else return { text: status, class: 'is-black' }
    },
    openSalesPage() {
      window.open(this.salesPage)
    },
    isShowSubscriptionUpdateButton() {
      const status =
        this.contract.status === 'ended' ||
        this.contract.status === 'canceled' ||
        this.contract.status === 'trialing' ||
        this.contract.status === 'processing'

      const method = this.contract.method === 'CREDIT_CARD'

      const gateway =
        this.contract.gateway_name === null ||
        this.contract.gateway_name === 'PAGARME'

      return !status && method && gateway
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} })
      }
      this.updateQueryParams(true) // ao enviar true, a listagem de contratos será atualizada!
    },
    openPanel(contract_id) {
      this.is_btn_disable = false
      this.visible = true
      this.contract_id = contract_id
      this.fetchContract()
    },
    cancelContract() {
      let data = {
        id: this.contract.id
      }

      service.destroy(data).then(response => {
        if (response.success) {
          this.is_btn_disable = true

          this.$bvToast.toast(
            this.$t('seller.contracts.right_preview.text_251'),
            {
              title: 'Contrato',
              variant: 'info',
              autoHideDelay: 5000,
              appendToast: true
            }
          )
          EventBus.$emit('relist-contracts')
          this.fetchContract()
        } else {
          this.$bvToast.toast(
            this.$t('seller.contracts.right_preview.text_252'),
            {
              title: this.$t('seller.contracts.right_preview.text_1812'),
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            }
          )
        }
      })
    },
    fetchContract() {
      this.contract = {
        contract: {
          live: {
            product: {
              name: '',
              description: '',
              images: [{ path: null }]
            }
          }
        }
      }
      this.loading = true
      service
        .read({ id: this.contract_id })
        .then(response => {
          this.contract = response
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading = false
        })
    },
    setColors(coin) {
      if (coin === 'BRL') return 'color: rgba(0, 132, 92, 1);'
      if (coin === 'USD') return 'color: rgba(255, 203, 55, 1);'
      if (coin === 'EUR') return 'color: rgb(40 30 176);'
      if (coin === 'GBP') return 'color: rgba(0, 0, 0, 1);'
      return 'color: #21a56e;'
    }
  }
}
</script>

<style lang="scss" scoped>
.open_boleto {
  color: #ff8200 !important;
  font-weight: 400;
  size: 14px;
}
.panel {
  .sumary {
    font-size: 13px;
    // padding-top: 20px;
    // padding-bottom: 20px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
  }

  .product-image {
    height: 170px;
    overflow: hidden;
    border-radius: 5px;
    background: #f5f2fc;
    display: flex;
    align-items: center;
  }
  .thumb-fake {
    width: 100%;
    color: silver;
    font-size: 32px;
    letter-spacing: 2px;
    justify-content: center;
  }

  .product-description {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #81858e;
    font-weight: normal;
  }
}
.name-product .hash {
  font-size: 12px;
  color: #81858e;
  font-weight: inherit;
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  // border: 1px solid ;
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 11px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.label-campo + .label-campo {
  margin-top: 5px;
}
.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex div {
  margin-bottom: 13px;
}
.btn-flex div button {
  width: 100%;
  height: 55px;
}
.btn-flex div button:hover {
  transform: none;
}

// collapse
.titulo-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.titulo-arrow.ativo {
  margin-bottom: 15px;
}
.titulo-arrow.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}

// history
.titulo-collapse {
  font-size: 13px !important;
}

.history-item {
  padding: 15px 0;
  position: relative;
}
.history-item::before {
  left: -10px;
  top: 50%;
}

.history-item + .history-item {
  // padding-top: 15px;
  border-top: 1px solid #ededf0;
}
.sem-historico {
  margin-top: 20px;
  font-size: 14px;
  color: #81858e;
}
.whats-cliente {
  font-size: 14px;
  color: #2a63ab;
}
.whats-cliente svg {
  margin-left: 5px;
}

.product-main {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  gap: 20px;
  align-items: center;
}
.img-product {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-product,
.img-product img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ededf0;
  object-fit: cover;
}
.name-product span {
  font-size: 11px;
  color: #81858e;
}
.name-product p {
  font-weight: 600;
}
.chip {
  width: 30px;
  height: 30px;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
</style>
