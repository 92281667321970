<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-end">
      <BaseHeader :title="$t('views.contracts.text_972')" />
      <SelectCurrency
        v-show="interAccess"
        :currency="currency"
        @update-coin="updateCoin"
      />
    </div>

    <div class="container-table">
      <!-- Total Amount Contracts -->
      <div class="box-total">
        <p class="total-vendas info">
          ⚡️ {{ itens }} {{ $t("views.contracts.text_2201") }}
        </p>
        <div>
          <p class="total-vendas">
            {{ $t("views.contracts.text_973") }}
            <span
              >{{ currency.currency_symbol }}
              {{ total ? formatMoney(+total) : "0,00" }}</span
            >
          </p>
        </div>
        <div></div>
      </div>
      <!-- Filters -->
      <div class="header-table">
        <div class="flex-sale">
          <div class="actions-flex">
            <b-tooltip
              target="filtros-2"
              :title="$t('views.contracts.text_2191')"
              placement="topright"
            />
            <b-button
              class="btn-table"
              @click="openFilters"
              :title="$t('views.contracts.text_2202')"
              id="filtros-2"
              ><img src="@/assets/img/icons/filtro.svg"
            /></b-button>
            <b-tooltip
              target="exportar-1"
              :title="$t('views.contracts.text_974')"
              placement="topright"
            />
            <b-button
              @click="exportData"
              class="btn-table"
              :title="$t('views.contracts.text_974')"
              id="exportar-1"
              ><img src="@/assets/img/icons/download.svg" alt=""
            /></b-button>
          </div>
          <div class="d-flex align-items-center flex-acoes">
            <div class="datas ml-0 ml-md-3 mb-2 mb-md-0">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                @update="updateDate"
                v-model="dateRange"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  :title="$t('views.contracts.text_2192')"
                >
                  {{ $t("views.contracts.text_2193") }}
                </template>
              </date-range-picker>
            </div>

            <b-col cols="12">
              <searchInput />
            </b-col>
          </div>
        </div>
      </div>

      <!-- Table Header -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="d-none d-md-block"
          >#{{ $t("views.contracts.text_2194") }}
        </b-col>

        <b-col cols="1" class="d-none d-md-block">
          {{ $t("views.contracts.text_2195") }}
        </b-col>

        <b-col cols="5" md="2" class="">
          {{ $t("views.contracts.text_2196") }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ $t("views.contracts.text_2197") }}
        </b-col>

        <b-col cols="3" class="d-none d-md-block">
          {{ $t("views.contracts.text_2198") }}
        </b-col>

        <b-col cols="4" md="2" class="">
          {{ $t("views.contracts.text_2199") }}
        </b-col>

        <b-col cols="3" md="1" class="">
          {{ $t("views.contracts.text_2196") }}
        </b-col>
      </b-row>

      <!-- Table Body -->
      <template v-if="!loading && contracts.length > 0">
        <b-row
          class="Table-body"
          v-for="item in contracts"
          :key="item.id"
          :class="getStatus(item.status).class"
        >
          <!-- Id -->
          <b-col
            cols="1"
            class="d-none d-md-block"
            id="id_venda"
            v-b-tooltip="{
              title: getStatus(item.status).text,
              placement: 'left',
            }"
          >
            # {{ item.id }}
          </b-col>
          <!-- Contract Start Date -->
          <b-col cols="1" class="d-none d-md-block">
            <span v-if="item.start_date">{{ item.start_date | date }}</span>
            <span v-else>-</span>
          </b-col>
          <!-- Product Name -->
          <b-col cols="5" md="2" class="">
            <div class="Table-body-title">
              {{
                setName(item).length > maxLength
                  ? setName(item).substr(0, maxLength) + ".."
                  : setName(item)
              }}
              <div class="offer-name">
                {{
                  item.contract.offer
                    ? `${$t("views.contracts.text_2203")}: ` +
                      item.contract.offer.name
                    : ""
                }}
              </div>
            </div>
          </b-col>
          <!-- Affiliate Name -->
          <b-col cols="2" class="d-none d-md-block">
            <template v-if="item.affiliate">
              {{ item.affiliate.name }}
            </template>
          </b-col>
          <!-- Client -->
          <b-col cols="3" class="d-none d-md-block dados-cliente">
            <div>
              {{
                item.client.name
                  ? item.client.name.length > maxLength
                    ? item.client.name.substr(0, maxLength) + "..."
                    : item.client.name
                  : "-"
              }}
            </div>

            <div>{{ item.client.email }}</div>

            <div>
              <a
                v-if="item.client.cellphone"
                :href="formatTel(item.client.cellphone)"
                target="_blank"
                class="whats-cliente"
                ><font-awesome-icon :icon="['fab', 'whatsapp']" />{{
                  item.client.cellphone
                }}</a
              >
            </div>
          </b-col>
          <!-- Method -->
          <b-col cols="4" md="2" class="valor-flex">
            <span>
              <div 
                v-if="item.gateway_name === 'STRIPE'"
                :id="`method-icon-${item.id}`"
                class="Table-icon-international"
              >
                <img src="@/assets/img/icons/international.svg" />
              </div>
              <font-awesome-icon
                v-else
                :icon="getIconMethod(item.method)"
                class="icon-gray"
                :id="`method-icon-${item.id}`"
              />
              <b-tooltip
                :target="`method-icon-${item.id}`"
                :title="getMethod(item.method, item.currency_id)"
                placement="left"
              />
            </span>
            <div
              v-if="
                item.history.length &&
                  item.history[0].method == 'BOLETO' &&
                  item.history[0].installments > 1
              "
            >
              <div v-if="item.contract.proposal">
                {{ currency.currency_symbol }}
                {{ formatMoney(+item.contract.proposal.original_amount) }}
              </div>
              <div v-if="!item.contract.proposal">
                {{ currency.currency_symbol }} 0,00
              </div>
            </div>
            <div class="" v-else>
              <div
                v-if="
                  lastPayment &&
                    item.history.length !== 0 &&
                    item.history.length > 1
                "
              >
                {{ currency.currency_symbol }}
                {{ formatMoney(+lastPayment(item.history)) }}
              </div>

              <div
                v-else-if="
                  lastPayment &&
                    item.history.length !== 0 &&
                    item.history.length <= 1
                "
              >
                <span v-if="item.history[0].balance !== null">
                  {{ currency.currency_symbol }}
                  {{ formatMoney(+lastPayment(item.history)) }}</span
                >
                <span
                  v-if="
                    (item.history[0].balance === null ||
                      !item.history[0].balance) &&
                      item.history[0].balance !== 0
                  "
                >
                  {{ currency.currency_symbol }} 0,00
                </span>
              </div>

              <div v-else-if="lastPayment && item.history.length === 0">
                {{ currency.currency_symbol }}
                {{
                  formatMoney(
                    item.contract.amount || item.contract.product.amount
                  )
                }}
              </div>

              <div v-if="!lastPayment">{{ currency.currency_symbol }} 0,00</div>
            </div>
          </b-col>
          <!-- View -->
          <b-col cols="3" md="1">
            <span class="Table-body-action" @click="openPanel(item.id)">
              <img src="@/assets/img/icons/eye.svg" />
            </span>
          </b-col>
        </b-row>
      </template>

      <Paginate
        v-if="contracts.length > 0 && !initExport"
        :totalPages="pagination.totalPages"
        :activePage="pagination.currentPage"
        @to-page="toPage"
        @per-page="perPage"
      />
      <!-- Loading -->
      <div
        v-if="(loading && contracts.length == 0) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>
      <!-- Feedback Message -->
      <b-row
        v-if="!loading && contracts.length == 0"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t("views.contracts.text_976") }}</p>
      </b-row>

      <!-- modals -->
      <FiltersModal @filter="filter" :contracts="contracts" />
      <ContractPreview
        :updateQueryParams="setQueryParams"
        ref="contractPreview"
      />
    </div>
    <!--<CallEvaluation /> -->
  </div>
</template>

<script>
import SelectCurrency from "@/components/SelectCurrency.vue";
import FiltersModal from "@/components/Seller/Contracts/modals/filters";
import searchInput from "@/components/Seller/Contracts/modals/searchInput";
import ContractPreview from "@/components/Seller/Contracts/rightPreview";
import Paginate from "@/components/shared/Paginate";
import { iconMethod, textMethod } from "@/helpers.js";
import { EventBus } from "@/main.js";
import Money from "@/mixins/money";
import _ from "lodash";
import moment from "moment-timezone";
import DateRangePicker from "vue2-daterange-picker";

import PermissionRedirect from "@/services/PermissionRedirect";
import ContractService from "@/services/resources/ContractService";

const service = ContractService.build();

export default {
  name: "Contratos",
  components: {
    Paginate,
    ContractPreview,
    FiltersModal,
    searchInput,
    DateRangePicker,
    SelectCurrency,
  },
  data() {
    return {
      filters: {},
      contracts: [],
      total: 0,
      loading: true,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      itens: 0,
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      initExport: false,
      currency: {
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$",
      },
      interAccess: false,
    };
  },
  mixins: [Money],
  filters: {
    next(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss")
        .add(1, "month")
        .format("DD/MM/YYYY");
    },
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2020, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
  },
  created() {
    let userInterIds = process.env.USER_INTER.substring(
      1,
      process.env.USER_INTER.length - 1
    ).split(",");
    this.interAccess = userInterIds.some(
      (userId) => parseInt(userId) === this.$store.getters.getProfile.id
    );
  },
  methods: {
    setName(item) {
      return item.contract.live.product.name;
    },
    getMethod(method, currency_id) {
      return textMethod(method, currency_id);
    },
    getIconMethod(method) {
      return iconMethod(method);
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ""
          )}`;
        }
      }
    },
    lastPayment(history) {
      let r = 0;
      [...history].reverse().find((item) => {
        if (item.status == "paid") {
          r = item.balance;
        }
      });
      return r;
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "trialing": {
            r = {
              text: this.$t("views.contracts.text_2385"),
              class: "is-warning",
            };
            break;
          }
          case "processing": {
            r = {
              text: this.$t("views.contracts.text_2386"),
              class: "is-warning",
            };
            break;
          }
          case "paid": {
            r = {
              text: this.$t("views.contracts.text_2387"),
              class: "is-black",
            };
            break;
          }
          case "pending_payment": {
            r = {
              text: this.$t("views.contracts.text_2388"),
              class: "is-warning",
            };
            break;
          }
          case "unpaid": {
            r = { text: this.$t("views.contracts.text_2389"), class: "is-red" };
            break;
          }
          case "canceled": {
            r = { text: this.$t("views.contracts.text_2390"), class: "is-red" };
            break;
          }
          case "ended": {
            r = { text: this.$t("views.contracts.text_2391"), class: "is-red" };
            break;
          }

          // status sales history
          case "chargedback": {
            r = { text: this.$t("status_sales.chargedback"), class: "is-red" };
            break;
          }
          case "refunded": {
            r = { text: this.$t("status_sales.refunded"), class: "is-red" };
            break;
          }

          case "waiting_payment": {
            r = { text: this.$t("status_sales.waiting_payment"), class: "is-red" };
            break;
          }

          case "refused": {
            r = { text: this.$t("status_sales.refused"), class: "is-red" };
            break;
          }

          case "pending_refund": {
            r = { text: this.$t("status_sales.refund_pending"), class: "is-red" };
            break;
          }

          case "refund_pending": {
            r = { text: this.$t("status_sales.refund_pending"), class: "is-red" };
            break;
          }
          
          case "delayed": {
            r = { text: this.$t("status_sales.delayed"), class: "is-red" };
            break;
          }

          default: {
            r = { text: status, class: "is-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    openPanel(contract_id) {
      this.$router.push({ query: { c_id: contract_id } }).catch(() => {});

      setTimeout(() => {
        this.$refs.contractPreview.openPanel(contract_id);
        this.loading = false;
      });
    },
    openFilters() {
      this.$bvModal.show("contracts-filters");
    },
    filter(filters) {
      this.filters = filters;
      this.fetchContracts();
    },
    toPage(page) {
      this.pagination.currentPage = page;
      // this.fetchContracts();
      this.$root.$emit("updateDataFilter", {});
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.$root.$emit("updateDataFilter", {});
    },
    updateDate() {
      this.pagination.currentPage = 1;
      this.$root.$emit("updateDataFilter", this.dateRange);
    },
    debounce: _.debounce(function() {
      this.fetchContracts(this.filters);
    }, 1000),
    download(data) {
      let file = "\uFEFF";// Adiciona o BOM para UTF-8
      file += "ID Contrato;Data início contrato;Próximo vencimento;Nome produto;Nome afiliado;Nome cliente;Documento;E-mail;Endereço;Telefone;Método de pagamento;Status contrato;ID Venda;Status venda;Valor recebido da venda;Data pagamento\n";

      for (let i = 0; i < data.length; i++) {
        // endereço cliente
        let endereco = data[i].client.street
          ? `${data[i].client.street} ${data[i].client.number} ${data[i].client.neighborhood} ${data[i].client.city} ${data[i].client.zipcode} ${data[i].client.complement}`
          : "";

        // data incio
        let data_inicio = data[i].start_date
          ? moment.tz(data[i].start_date, "America/Sao_Paulo").format("DD/MM/YY H:mm")
          : "";

        // proximo vencimento
        let current_period_end = data[i].current_period_end
          ? moment.tz(data[i].current_period_end, "America/Sao_Paulo").format("DD/MM/YY H:mm")
          : "";

        // afiliado
        let afiliado = data[i].affiliate ? data[i].affiliate.name : "";

        // valor liquido
        let valor_liquido = 0;
        if (data[i].history.length && data[i].history[0].method == "BOLETO" && data[i].history[0].installments > 1) {
          if (data[i].contract.proposal) {
            valor_liquido = this.formatMoney(+data[i].contract.proposal.original_amount);
          }
        }

        if (this.lastPayment && data[i].history.length !== 0 && data[i].history.length > 1) {
          valor_liquido = this.formatMoney(+this.lastPayment(data[i].history));
        } else if (this.lastPayment && data[i].history.length !== 0 && data[i].history.length <= 1) {
          if (data[i].history[0].balance !== null) {
            valor_liquido = this.formatMoney(+this.lastPayment(data[i].history));
          }
        } else if (this.lastPayment && data[i].history.length === 0) {
          valor_liquido = this.formatMoney(data[i].contract.amount || data[i].contract.product.amount);
        } else {
          valor_liquido = 0;
        }

        // montando linhas para cada transação
        data[i].history.forEach(history => {
          let telefone = data[i].client.cellphone 
              ? String(data[i].client.cellphone).replace(/^\+/, '').slice(2).replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
              : "";

          let displayValue = ["paid", "chargedback", "refund_pending", "refunded"].includes(history.status) 
            ? this.formatMoney(history.balance) 
            : "";

          let displayPaidAt = ["paid", "chargedback", "refund_pending", "refunded"].includes(history.status) 
            ? (moment.tz(history.paid_at, "America/Sao_Paulo").isValid() 
                ? moment.tz(history.paid_at, "America/Sao_Paulo").format("DD/MM/YY H:mm") 
                : "")
            : "";

            file +=
              data[i].id + ";" +
              data_inicio + ";" +
              current_period_end + ";" +
              data[i].contract.product.name + ";" +
              afiliado + ";" +
              data[i].client.name + ";" +
              data[i].client.document + ";" +
              data[i].client.email + ";" +
              endereco + ";" +
              telefone + ";" +
              this.getMethod(data[i].method, data[i].currency_id) + ";" +
              this.getStatus(data[i].status).text + ";" +
              history.id + ";" +
              this.getStatus(history.status).text + ";" +
              displayValue + ";" +
              displayPaidAt + "\n";
        });
      }


      const filename = "Voomp_" + moment().format("DD-MM-YYYY") + ".csv";
      const fileWithBOM = "\uFEFF" + file;
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/csv;charset=ISO-8859-1," + encodeURIComponent(fileWithBOM)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.$bvToast.toast(this.$t("views.contracts.text_977"), {
        variant: "success",
        title: this.$t("views.contracts.text_2204"),
        autoHideDelay: 5000,
        appendToast: true,
      });
      this.initExport = true;
    },
    exportData() {
      this.fetchContracts(this.filters, true);
    },
    fetchContracts(dadosFiltro, exportData = false) {
      if (typeof this.$route.query.c_id == "undefined") {
        this.$router
          .replace({
            query: {
              start_date: moment(this.dateRange.startDate).format("MM-DD-YYYY"),
              end_date: moment(this.dateRange.endDate).format("MM-DD-YYYY"),
              ...this.filters,
            },
          })
          .catch(() => {});
      }
      this.loading = true;
      let data = {
        page: this.pagination.currentPage,
      };

      if (!exportData) {
        this.contracts = [];
        this.pagination.totalPages = 1;
        this.total = 0;
        this.itens = 0;
        data.per_page = this.pagination.perPage;
      } else {
        this.initExport = true;
        this.$bvToast.toast(this.$t("views.contracts.text_978"), {
          variant: "info",
          title: this.$t("views.contracts.text_2204"),
          autoHideDelay: 8000,
          appendToast: true,
        });
      }

      data = Object.assign(data, dadosFiltro);
      delete data.startDate;
      delete data.endDate;

      data.date_start = this.dateRange.startDate
        ? moment(this.dateRange.startDate, "MM-DD-YYYY")
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DDT00:00:00-03:00")
        : null;

      data.date_end = this.dateRange.endDate
        ? moment(this.dateRange.endDate, "MM-DD-YYYY")
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DDT23:59:59-03:00")
        : null;

      data.page = this.pagination.currentPage;

      if (exportData) data.export = true;

      service
        .search(data)
        .then((response) => {
          if (exportData) {
            this.download(response.data);
            this.initExport = false;
          } else {
            this.contracts = response.data.data;
            this.pagination.totalPages = response.data.last_page;
            this.total = response.total;
            this.itens = response.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCoin(coin) {
      this.currency = coin;
      this.pagination.currentPage = 1;
      this.pagination.perPage = 10;
      this.fetchContracts();
    },
    setQueryParams(isNotToRefresh) {
      this.dateRange.startDate =
        this.$route.query.start_date || this.dateRange.startDate || moment();
      this.dateRange.endDate =
        this.$route.query.end_date || this.dateRange.endDate || moment();
      this.filters.status =
        this.$route.query.status || this.filters.status || [];
      this.filters.method =
        this.$route.query.method || this.filters.method || [];
      this.filters.products =
        this.$route.query.products || this.filters.products || [];
      this.filters.affiliates =
        this.$route.query.affiliates || this.filters.affiliates || [];
      if (isNotToRefresh) {
        this.$router
          .replace({
            query: {
              start_date: moment(this.dateRange.startDate).format("MM-DD-YYYY"),
              end_date: moment(this.dateRange.endDate).format("MM-DD-YYYY"),
              ...this.filters,
            },
          })
          .catch(() => {});
      } else {
        this.fetchContracts();
      }
    },
  },
  mounted() {
    if (JSON.parse(localStorage.user).country)
      this.currency = JSON.parse(localStorage.user).country.base_currency;

    // abrindo venda unica
    if (typeof this.$route.query.c_id != "undefined") {
      setTimeout(() => {
        this.$refs.contractPreview.openPanel(this.$route.query.c_id);
        this.loading = false;
      }, 1000);
    } else {
      this.setQueryParams();
    }
    EventBus.$on("relist-contracts", () => {
      this.fetchContracts();
    });
    this.$root.$on("updateDataFilter", (data) => {
      if (data.page == 1) {
        this.pagination.currentPage = 1;
      }
      this.filters = Object.assign(this.filters, data);
      this.debounce();
    });
    PermissionRedirect("ContractRead");
  },
};
</script>

<style scoped>
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 33, 99, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 33, 99, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.btns-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 190px;
  font-size: 14px !important;
  color: #81858e !important;
  text-align: center;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.Table-body {
  padding: 15px;
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2),
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}
.whats-cliente {
  font-size: 13px;
  color: #002363;
}
.whats-cliente svg {
  margin-right: 10px;
}
.total-vendas {
  margin: 0;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.flex-sale,
.actions-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actions-flex {
  gap: 10px;
  justify-content: flex-start;
}
.valor-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 768px) {
  .search {
    left: 20px !important;
  }
  .input-busca {
    width: 300px;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
    padding-left: 0;
  }

  .flex-acoes {
    display: block !important;
    margin: 15px 0;
  }
}
</style>
